// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Manoj Kumar Thapa Portfolio",
  description:
    "I am a software engineer with industry experience building dynamic web applications. I have created REST APIs and web services using Spring Boot and designed databases. Additionally, I have developed web applications using JavaScript and React. Over the course of my career, I have leveraged both React and Spring Boot technologies to contribute to several projects.",
  og: {
    title: "Manoj Kumar Thapa Portfolio",
    type: "website",
    url: "https://manojthapa.hamropedia.com",
  },
};

//Home Page
const greeting = {
  title: "Manoj Kumar Thapa",
  logo_name: "Manoj Kumar Thapa",
  nickname: "Software Engineer",
  subTitle:
    "I am a software engineer with industry experience building dynamic web applications. I have created REST APIs and web services using Spring Boot and designed databases. Additionally, I have developed web applications using JavaScript and React. Over the course of my career, I have leveraged both React and Spring Boot technologies to contribute to several projects.",
  resumeLink:
    "https://drive.google.com/file/d/1tNtWA0fgpxb50s6Y_Srg855NJBgOOZtP/view?usp=sharing",
  portfolio_repository: "https://github.com/Manoj-Thapa",
  githubProfile: "https://github.com/Manoj-Thapa",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/Manoj-Thapa",
    fontAwesomeIcon: "fa-github",
    backgroundColor: "#181717",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/manoj-kumar-thapa-7595a5168",
    fontAwesomeIcon: "fa-linkedin-in",
    backgroundColor: "#0077B5",
  },
  {
    name: "Blog",
    link: "https://www.hamropedia.com",
    fontAwesomeIcon: "fas fa-blog",
    backgroundColor: "#1DA1F2",
  },
  {
    name: "Gmail",
    link: "mailto:thapam807@gmail.com",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive and dynamic websites using JavaScript and React.",
        "⚡ Developing REST APIs and web services using Java and Spring Boot.",
        "⚡ Integrating APIs with frontend technologies to deliver complete full-stack solutions.",
        "⚡ Utilizing Docker for containerization and efficient deployment.",
        "⚡ Managing version control and collaboration using Git.",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "logos:react",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Spring Boot",
          fontAwesomeClassname: "simple-icons:springboot",
          style: {
            color: "#6DB33F",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#2496ED",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#F05032",
          },
        },
      ],
    },
    {
      title: "Data Science & AI",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing scalable production-ready models for deep learning and statistical use cases.",
        "⚡ Experience working with computer vision and NLP projects.",
        "⚡ Complex quantitative modeling for dynamic forecasting and time series analysis.",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Keras",
          fontAwesomeClassname: "simple-icons:keras",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Education",
      iconifyClassname: "simple-icons:bookstack",
      style: {
        color: "#9B5DE5",
      },
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Dr. Ambedkar Institute of Technology, Bangalore",
      subtitle: "Bachelor of Engineering in Computer Science and Engineering",
      logo_path: "drait_logo.png",
      alt_name: "Dr. AIT, Bangalore",
      duration: "2017 - 2021",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Deep Learning, Machine Learning, Data Science and Full Stack Development.",
        "⚡ Graduated with 9.45 CGPA.",
        "⚡ Department 3rd topper and 2nd Nepali topper during graduation.",
      ],
      website_link: "https://www.drait.edu.in",
    },
    {
      title: "Manimukunda College/HSS",
      subtitle: "Class 12",
      logo_path: "mmc_logo.png",
      alt_name: "MMC, Butwal, Nepal",
      duration: "2015 - 2017",
      descriptions: [
        "⚡ I have studied Maths, Biology, Physics, Chemistry, English and Nepali.",
        "⚡ Awarded with Mahatma Gandhi Scholarship.",
        "⚡ Achieved 75.8%.",
      ],
      website_link: "https://www.manimukundacollege.edu.np",
    },
    {
      title: "New Horizon Institute",
      subtitle: "Class 10",
      logo_path: "nhi_logo.png",
      alt_name: "NHI, Butwal, Nepal",
      duration: "2014 - 2015",
      descriptions: [
        "⚡ I have studied Comp. Maths, Physics, Social Studies, Opt. Maths, Nepali, English, HPE and Computer Science.",
        "⚡ Achieved 85.63%.",
      ],
      website_link: "https://newhorizon.edu.np",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/W29WV2DFL476",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/certificate/MLU4694ENBEL",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Convolutional Neural Networks",
      subtitle: "- GUVI Training",
      logo_path: "guvi_logo.png",
      certificate_link: "https://www.guvi.in/certificate?id=gfH250280A8ru61p75",
      alt_name: "guvi.in",
      color_code: "#0C9D5899",
    },
    {
      title: "JavaScript",
      subtitle: "- GUVI Training",
      logo_path: "guvi_logo.png",
      certificate_link:
        "https://www.guvi.in/verify-certificate?id=c1xSTag2216t88671R&course=javascript_en",
      alt_name: "guvi.in",
      color_code: "#0C9D5899",
    },
    {
      title: "Data Visualization using Python",
      subtitle: "- Great Learning",
      logo_path: "greatlearning_logo.png",
      certificate_link:
        "https://olympus1.greatlearning.in/course_certificate/ZMICNLYY",
      alt_name: "Great Learning",
      color_code: "#1F70C199",
    },
    {
      title: "Data Visualization using Tableau",
      subtitle: "- Great Learning",
      logo_path: "greatlearning_logo.png",
      certificate_link:
        "https://olympus1.greatlearning.in/course_certificate/DLEKCAPR",
      alt_name: "Great Learning",
      color_code: "#1F70C199",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work and Internship",
  description:
    "I am a software engineer with industry experience. I specialize in building responsive website front ends using React-Redux, leveraging my expertise in JavaScript and React to create dynamic user interfaces. Additionally, my proficiency in developing REST APIs and web services using Spring Boot has enabled seamless integration with frontend technologies, delivering comprehensive full stack solutions.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Computer Science and Programming Instructor",
          company: "Birgatha Savings and Credit Co-operative Society Ltd.",
          company_url: "",
          logo_path: "birgatha_logo.png",
          duration: "June 2022 - Present",
          location: "Butwal, Nepal",
          description:
            "In my role at Birgatha, I taught computer science and programming to students and community members, helping them enhance their technical skills. I designed and conducted interactive workshops and classes, ensuring that the learning process was both enjoyable and effective for participants. Through these initiatives, I aimed to foster a deeper understanding of programming concepts and practical applications.",
          color: "#A100FE",
        },
        {
          title: "Application Development Associate",
          company: "Accenture",
          company_url: "https://www.accenture.com",
          logo_path: "accenture_logo.png",
          duration: "October 2021 - May 2022",
          location: "Bangalore, India",
          description:
            "During my role at Accenture, I developed simple web applications using JavaScript and React. I worked extensively with REST APIs and web services, leveraging Spring Boot for backend development. Additionally, I focused on creating dynamic and browser-compatible pages using HTML5, CSS3, and JavaScript (React, Redux). My work involved translating UI/UX design wireframes into high-quality, functional markup to enhance user experience and interface performance.",
          color: "#A100FE",
        },
      ],
    },

    {
      title: "Internships",
      experiences: [
        {
          title: "Full Stack Developer Intern",
          company: "GUVI",
          company_url: "https://www.guvi.in",
          logo_path: "greenguvi_logo.png",
          duration: "April 2021 - October 2021",
          location: "Work From Home",
          description:
            "During my full-stack developer internship at GUVI, I successfully undertook various responsibilities, including the creation of diverse templates such as banner design and resume design. I gained a comprehensive understanding of new ES6 features as well as concepts like callbacks, promises, async/await, and the fetch API. Additionally, I acquired proficiency in different DOM manipulation techniques and developed simple yet effective DOM templates like Pagination Design and HTML Form Design. Furthermore, I expanded my skill set to include the building of RESTful APIs and obtained a solid grasp of MongoDB. As a practical application of my knowledge, I successfully developed a web application capable of fetching market value prices from an API and storing the data in MongoDB.",
          color: "#09D15E",
        },
        {
          title: "Web Development Intern",
          company: "The Sparks Foundation",
          company_url: "https://www.thesparksfoundationsingapore.org",
          logo_path: "sparksfoundation_logo.png",
          duration: "October 2020 - November 2020",
          location: "Work From Home",
          description:
            "During my web developer internship, I actively contributed to the development of new user-facing features, emphasizing the creation of reusable code and libraries for future applications. I played a crucial role in ensuring the technical feasibility of MongoDB, Express.JS, and Node.JS within the projects. Additionally, I focused on optimizing applications for maximum speed and scalability and implemented robust validation processes to assure the integrity of all user inputs before submission to the backend.",
          color: "#040404",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of the latest technological tools. My best experience is creating full-stack web development projects, and I have also worked on machine learning and deep learning projects.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "I have worked on and published a few research papers.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "manojkumarthapa.png",
    description:
      "You can message me, and I will reply within 24 hours. I can help you with building full-stack solutions with technologies like JavaScript, React.JS, Java, Spring Boot, Docker, and Git.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I specialize in crafting insightful technical articles focused on programming. With a keen understanding of complex concepts, my articles aim to provide clear and valuable insights for readers in the programming community.",
    link: "https://www.hamropedia.com",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Butwal, Nepal",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/8i7v2ikBUBCXx3wn6",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+977 9807519618",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
